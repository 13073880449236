import { JWT, fetchAuthSession } from 'aws-amplify/auth'

type GetToken = { accessToken: JWT | undefined; idToken: JWT | undefined } | undefined
export async function getAccessToken(): Promise<GetToken> {
    try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {}
        return { accessToken, idToken }
    } catch (err) {
        console.log(err)
    }
}
