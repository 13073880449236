import { SortingKeys } from 'types/property-management.types'

export const PROPERTY_MANAGEMENT_APIS = Object.freeze({
    LIST_PROPERTY: (
        page: number | undefined,
        limit: number | undefined,
        search: string,
        property_type: string[] | null | undefined,
        active: string[] | null | undefined,
        bedrooms: string[] | null | undefined,
        bathrooms: string[] | null | undefined,
        city: string[] | null | undefined,
        status: string[] | null | undefined,
        priceFrom: number | null | undefined,
        priceTo: number | null | undefined,
        sortingOptions: SortingKeys
    ) => {
        let url = `/properties?page=${page}&limit=${limit}&site=admin`
        if (search) {
            url += `&search=${search}`
        }
        if (property_type) {
            url += `&property_type=${property_type}`
        }
        if (active) {
            url += `&active=${active}`
        }
        if (bedrooms) {
            url += `&bedrooms=${bedrooms}`
        }
        if (bathrooms) {
            url += `&bathrooms=${bathrooms}`
        }
        if (city) {
            url += `&city=${city}`
        }
        if (status) {
            url += `&status=${status}`
        }
        if (priceFrom && priceTo) {
            url += `&price_from=${priceFrom}&price_to=${priceTo}`
        }
        if (sortingOptions?.sort) {
            url += `&sort=${sortingOptions?.sort}`
        }
        return url
    },
    SINGLE_PROPERTY: (id: string) => `/properties/${id}`,
    CREATE_PROPERTY: '/properties',
    UPDATE_PROPERTY: (id: string) => `/properties/${id}`,
    DUPLICATE_PROPERTY: `/properties/duplicate`,
    DELETE_PROPERTY: (id: string) => `/properties/${id}`,
    PROPERTY_TAGS: '/property-filters',
    ALL_AMENITIES: '/amenities',
    CREATE_PROPERTY_AMENITY: '/properties/amenities',
    GET_PROPERTY_AMENITY: (id: string) => `/property-amenities/${id}`,
    UPDATE_PROPERTY_AMENITY: (id: string) => `/properties/amenities/${id}`,
    DELETE_PROPERTY_AMENITY: (id: string) => `/properties/amenities/${id} `,
    CREATE_PROPERTY_BLOCKS: `/properties/rooms`,
    GET_PROPERTY_BLOCKS: (id: string) => `/property-rooms/${id}`,
    UPDATE_PROPERTY_BLOCKS: (id: string) => `/properties/rooms/${id}`,
    DELETE_PROPERTY_BLOCKS: (id: string) => `/properties/rooms/${id}`,
    CITIES: '/city'
})

export const FILE_UPLOAD_URL = Object.freeze({
    path: `/upload-file`
})

export const WEBSITE_CONTENT_APIS = Object.freeze({
    WEBSITE_CONTENT_ABOUT_GET: (param: string) => `/website-content?pagename=${param}`,
    WEBSITE_CONTENT_ABOUT_PATCH: (param: string) => `/website-content?pagename=${param}`,
    LIST_FAQ_GET: (
        page: number | undefined,
        limit: number | undefined,
        property_type: string | undefined,
        search: string,
        category: string | null | undefined
    ) => {
        let url = `/faq?page=${page}&limit=${limit}&property_type=${property_type}`
        if (search) {
            url += `&search=${search}`
        }
        if (category) {
            url += `&category=${category}`
        }
        return url
    },
    FAQ_POST: `/faq`,
    FAQ_UPDATE: (id: string) => `/faq/${id}`,
    FAQ_DELETE: (id: string) => `/faq/${id}`,

    LIST_PROPERTY_GET: () => `/website-content?pagename=list_your_property`,
    LIST_PROPERTY_UPDATE: `/website-content?pagename=list_your_property`,

    LANDING_PAGE_GET: (param: string) => `/website-content?pagename=${param}`,
    LANDING_PAGE_UPDATE: (param: string) => `/website-content?pagename=${param}`,

    ALL_ADDRESS: (page: number | undefined, limit: number | undefined, city: string, address: string) => {
        let url = `/address?page=${page}&limit=${limit}`
        if (address && city) {
            url += `&city_id=${city}&address=${address}`
        } else if (address) {
            url += `&address=${address}`
        } else if (city) {
            url += `&city_id=${city}`
        }
        return url
    },
    ADDRESS_GET: () => `/address`,
    ADDRESS_CREATE: () => `/address`,
    ADDRESS_UPDATE: (id: string) => `/address/${id}`,
    ADDRESS_DELETE: (id: string) => `/address/${id}`,
    SINGLE_ADDRESS: (id: string) => `/address/${id}`,

    LIST_TESTIMONIALS: (page: number | undefined, limit: number | undefined) => {
        const url = `/testimonials?page=${page}&limit=${limit}`
        return url
    },
    TESTIMONIALS_CREATE: () => `/testimonials`,
    TESTIMONIALS_GET: () => `/testimonials`,
    TESTIMONIALS_UPDATE: (id: string) => `/testimonials/${id}`,
    TESTIMONIALS_DELETE: (id: string) => `/testimonials/${id}`,

    CONTACT_US_GET: () => `/website-content?pagename=contact_us`,
    CONTACT_US_PATCH: () => `/website-content?pagename=contact_us`
})

export const USER_MANAGEMENT_APIS = Object.freeze({
    LIST_USERS: (page: number | undefined, limit: number | undefined, search: string, status: string) => {
        let url = `/users?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        if (status) {
            url += `&status=${status}`
        }
        return url
    },
    USER_ROLES: `/roles`,
    CREATE_USER: `/users`,
    UPDATE_USER: (id: string) => `/users/${id}`,
    USER_PROFILE: (cognitoId: string) => `/users/profile/${cognitoId}`,
    RESET_PASSOWRD: `/reset-password`
})

export const LEADS_APIS = Object.freeze({
    LIST_LEADS: (
        type: string,
        page: number | undefined,
        limit: number | undefined,
        status: string,
        search: string | undefined
    ) => {
        let url = `/leads?type=${type}&page=${page}&limit=${limit}&status=${status}`
        if (search) {
            url += `&search=${search}`
        }
        return url
    },
    LEADS_UPDATE: (id: string) => `/leads/${id}`
})

export const BLOGS_APIS = Object.freeze({
    LIST_BLOGS: (page: number | undefined, limit: number | undefined, search: string, status: string) => {
        let url = `/website-content/blog?page=${page}&limit=${limit}`
        if (search) {
            url += `&search=${search}`
        }
        if (status) {
            url += `&active=${status}`
        }
        return url
    },
    BLOG_TAGS: `/blog-tags`,
    CREATE_BLOG: `/website-content/blog`,
    SINGLE_BLOG: (id: string) => `/website-content/blog/${id}`,
    UPDATE_BLOG: (id: string) => `/website-content/blog/${id}`,
    DELETE_BLOG: (id: string) => `/website-content/blog/${id}`
})
