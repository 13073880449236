import { Result } from 'antd'
import React, { Component, ReactNode } from 'react'

type myProps = {
    children?: ReactNode
}

type MyState = {
    hasError: boolean
}

export default class ErrorBoundry extends Component<myProps, MyState> {
    constructor(props: myProps | Readonly<myProps>) {
        super(props)
        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error: Error): {
        hasError: boolean
        error: Error
    } {
        return {
            hasError: true,
            error
        }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.log(error, errorInfo)
    }

    render(): string | number | boolean | Iterable<React.ReactNode> | JSX.Element | null | undefined {
        if (this.state.hasError) {
            return <Result status={'500'} title="Something went wrong try again later" />
        }
        return this.props.children
    }
}
