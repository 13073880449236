import React, { useEffect } from 'react'

function getWindowDimensions(): {
    width: number
    height: number
} {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height
    }
}

export default function useWindowDimensions(): { width: number; height: number } {
    const [windowDimesion, setWindowDimension] = React.useState(getWindowDimensions())
    useEffect(() => {
        function handleResize(): void {
            setWindowDimension(getWindowDimensions())
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowDimesion
}
