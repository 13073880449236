import { lazy } from 'react'
import { RouteTypes } from 'types/common.types'

//Auth Routes
const ChangePassword = lazy(() => import('components/auth/change-password'))
const ForgotPassword = lazy(() => import('components/auth/forgot-password'))
const SignIn = lazy(() => import('components/auth/sign-in'))

//Protected Routes
const DashboardLeads = lazy(() => import('components/dashboard/leads'))
const DashboardProperties = lazy(() => import('components/dashboard/properties'))
const Blogs = lazy(() => import('components/blogs'))
const BlogCreation = lazy(() => import('components/blogs/blogs-create-update'))
const Leads = lazy(() => import('components/leads'))
const PropertyMangement = lazy(() => import('components/property-management'))
const UserMangement = lazy(() => import('components/user-management'))
const Home = lazy(() => import('components/website-content/home'))
const AboutUs = lazy(() => import('components/website-content/about'))
const Contact = lazy(() => import('components/website-content/contact'))
const ListProperty = lazy(() => import('components/website-content/list-property'))
const CompanyInformation = lazy(() => import('components/website-content/company-info'))
const Faq = lazy(() => import('components/website-content/faq'))
const CreateProperty = lazy(() => import('components/property-management/create-update-property'))
const ViewProperty = lazy(() => import('components/property-management/view-property'))
const Testimonials = lazy(() => import('components/website-content/testimonials'))
const Address = lazy(() => import('components/website-content/address'))
const CreateAddress = lazy(() => import('components/website-content/address/create-address'))
const TermsConditions = lazy(() => import('components/website-content/terms-condition'))
const PrivacyPolicy = lazy(() => import('components/website-content/privacy-policy'))

export const authRoutes: RouteTypes[] = [
    { path: '/forgot-password', name: 'Forgot password', routeKey: 'forgot-password', element: ForgotPassword },
    { path: '/sign-in', name: 'Sign in', routeKey: 'sign-in', element: SignIn }
]

export const routes: RouteTypes[] = [
    { path: '/dashboard-leads', name: 'Dashboard leads', routeKey: 'dashboard-leads', element: DashboardLeads },
    {
        path: '/dashboard-properties',
        name: 'Dahboard properties',
        routeKey: 'dashboard-properties',
        element: DashboardProperties
    },
    {
        path: '/home',
        name: 'Home ',
        routeKey: 'home',
        element: Home
    },
    {
        path: '/about',
        name: 'About ',
        routeKey: 'about',
        element: AboutUs
    },
    {
        path: '/contact',
        name: 'Contact ',
        routeKey: 'contact',
        element: Contact
    },
    {
        path: '/list-property',
        name: 'List property ',
        routeKey: 'list-property',
        element: ListProperty
    },
    {
        path: '/faq',
        name: 'FAQ',
        routeKey: 'faq',
        element: Faq
    },

    {
        path: '/property-management',
        name: 'Property management',
        routeKey: 'property-management',
        element: PropertyMangement
    },
    {
        path: '/property-management/create-property',
        name: 'Create property',
        routeKey: 'create-property',
        element: CreateProperty
    },
    {
        path: '/property-management/view-property/:property_view_id',
        name: 'View property',
        routeKey: 'view-property',
        element: ViewProperty
    },
    {
        path: '/property-management/create-property/:property_create_id',
        name: 'Update property',
        routeKey: 'update-property',
        element: CreateProperty
    },
    {
        path: '/property-management/update-property/:property_update_id',
        name: 'Update property',
        routeKey: 'update-property',
        element: CreateProperty
    },
    {
        path: '/leads/:leadType',
        name: 'Leads',
        routeKey: 'leads',
        element: Leads
    },
    {
        path: '/user-management',
        name: 'User management',
        routeKey: 'user-management',
        element: UserMangement
    },
    {
        path: '/blogs',
        name: 'Blogs',
        routeKey: 'blogs',
        element: Blogs
    },
    {
        path: '/blogs-create',
        name: 'Blogs',
        routeKey: 'blogs-create',
        element: BlogCreation
    },
    {
        path: '/blog-update/:blogUpdateId',
        name: 'Blogs',
        routeKey: 'blogs-update',
        element: BlogCreation
    },
    {
        path: '/list-property',
        name: 'List property ',
        routeKey: 'list-property',
        element: ListProperty
    },
    {
        path: '/company-information',
        name: 'Company information ',
        routeKey: 'company-information',
        element: CompanyInformation
    },
    {
        path: '/faq',
        name: 'FAQ',
        routeKey: 'faq',
        element: Faq
    },
    { path: '/blogs/:blogId', name: 'Blogs', routeKey: 'blogs-single', element: Blogs },
    {
        path: '/testimonials',
        name: 'Testimonials',
        routeKey: 'testimonials',
        element: Testimonials
    },
    {
        path: '/address',
        name: 'Address',
        routeKey: 'address',
        element: Address
    },
    {
        path: '/address-create',
        name: 'Address create',
        routeKey: 'address-create',
        element: CreateAddress
    },
    {
        path: '/address-update/:addressUpdateId',
        name: 'Address update',
        routeKey: 'address-update',
        element: CreateAddress
    },
    {
        path: '/terms-conditions',
        name: 'Terms and conditions',
        routeKey: 'terms-conditions',
        element: TermsConditions
    },
    {
        path: '/privacy-policy',
        name: 'Privacy policy',
        routeKey: 'privacy-policy',
        element: PrivacyPolicy
    }
]

export const noLayoutRoutes: RouteTypes[] = [
    { path: '/change-password', name: 'Change password', routeKey: 'change-password', element: ChangePassword }
]

// Define roles that are allowed to access each route
export const allowedRoles: { [key: string]: string[] } = {
    '/property-management': ['admin', 'property_manager'],
    '/property-management/create-property': ['admin', 'property_manager'],
    '/property-management/view-property/:property_view_id': ['admin', 'property_manager'],
    '/property-management/create-property/:property_create_id': ['admin', 'property_manager'],
    '/property-management/update-property/:property_update_id': ['admin', 'property_manager'],
    '/user-management': ['admin'],
    '/leads/:leadType': ['admin', 'sales'],
    '/blogs-create': ['admin', 'content_manager'],
    '/blog-update/:blogUpdateId': ['admin', 'content_manager'],
    '/blogs': ['admin', 'content_manager'],
    '/list-property': ['admin', 'content_manager'],
    '/contact': ['admin', 'content_manager'],
    '/about': ['admin', 'content_manager'],
    '/home': ['admin', 'content_manager'],
    '/company-information': ['admin', 'content_manager'],
    '/company-information/create-address': ['admin', 'content_manager'],
    '/faq': ['admin', 'content_manager'],
    '/testimonials': ['admin', 'content_manager'],
    '/address': ['admin', 'content_manager'],
    '/address-create': ['admin', 'content_manager'],
    '/address-update/:addressUpdateId': ['admin', 'content_manager'],
    '/terms-conditions': ['admin', 'content_manager'],
    '/privacy-policy': ['admin', 'content_manager']
}

export const allowedMenus: { [key: string]: string[] } = {
    dashboard: ['admin'],
    'property-management': ['admin', 'property_manager'],
    'user-management': ['admin'],
    'website-content': ['admin', 'content_manager'],
    blogs: ['admin', 'content_manager'],
    leads: ['admin', 'sales']
}

export const allowedRoutes = (userRoles: string[]): RouteTypes[] => {
    const filteredRoutes = routes.filter((route) => {
        // Check if the route exists in allowedRoles and if the user has one of the allowed roles
        return allowedRoles[route.path] && allowedRoles[route.path].some((role) => userRoles.includes(role))
    })
    return filteredRoutes
}
