import React, { useContext, useState } from 'react'
import { Button, Flex, Modal, message } from 'antd'
import { signOut } from 'aws-amplify/auth'
import { CommonStateContext } from 'components/common-components/common-states/common-states'
import authStyle from '../styles/auth.module.scss'
import { useNavigate } from 'react-router-dom'
import ErrorBoundry from 'utils/error-boundary'

// import authStyle from '../styles/auth.module.scss'

const Logout: React.FC = () => {
    const { logOutOpen, setLogOutOpen } = useContext(CommonStateContext)
    const [loading, setLoading] = useState<boolean>(false)
    const naigate = useNavigate()
    const handleOk = (): void => {
        setLogOutOpen(false)
    }

    const handleCancel = (): void => {
        setLogOutOpen(false)
    }

    const logOut = async (): Promise<void> => {
        try {
            setLoading(true)
            await signOut()
            message.success('Logged out successfully')
            setLoading(false)
            setLogOutOpen(false)
            naigate('/auth/sign-in')
        } catch (error) {
            console.log('error signing out: ', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal centered open={logOutOpen} onOk={handleOk} onCancel={handleCancel} footer={null} width="auto">
            <ErrorBoundry>
                <div className={authStyle.logOutMain}>
                    <h4 className={authStyle.heading}>Are you sure you want to logout?</h4>
                    <Flex align="center" justify="center" gap={10} className={authStyle.actionBtns}>
                        <Button className={authStyle.logoutBtns} onClick={handleCancel} type="primary" ghost>
                            Stay
                        </Button>
                        <Button loading={loading} onClick={logOut} className={authStyle.logoutBtns} type="primary">
                            Logout
                        </Button>
                    </Flex>
                </div>
            </ErrorBoundry>
        </Modal>
    )
}

export default Logout
