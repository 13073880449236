import { UseMutationResult, UseQueryResult, keepPreviousData, useMutation, useQuery } from '@tanstack/react-query'
import { PaginationTypes } from 'types/common.types'
import {
    USERS_MANAGEMENT_GET,
    USERS_MANAGEMENT_PATCH,
    USERS_MANAGEMENT_POST,
    USERS_RESET_PASSWORD,
    USER_MANAGEMENT_ROLES_GET,
    USER_PROFILE_GET
} from './api-helper'
import { UsersRoot, resetPasswordType, userSingleData, userType, usersData } from 'types/user-management.types'

export const USE_GET_LIST_USERS = (
    pagination: PaginationTypes,
    search: string,
    status: string
): UseQueryResult<UsersRoot, Error> =>
    useQuery({
        queryKey: ['USE_GET_LIST_USERS', pagination.current, pagination.pageSize, search, status],
        queryFn: () => USERS_MANAGEMENT_GET(pagination.current, pagination.pageSize, search, status),
        retry: 0,
        placeholderData: keepPreviousData
    })

export const USE_USER_PROFILE = (cognitoId: string): UseQueryResult<userSingleData, Error> =>
    useQuery({
        queryKey: ['USE_USER_PROFILE', cognitoId],
        queryFn: () => USER_PROFILE_GET(cognitoId),
        placeholderData: keepPreviousData,
        enabled: !!cognitoId
    })

export const USE_GET_ROLES_USERS = (): UseQueryResult<usersData, Error> =>
    useQuery({
        queryKey: ['USE_GET_ROLES_USERS'],
        queryFn: () => USER_MANAGEMENT_ROLES_GET(),
        placeholderData: keepPreviousData
    })

export const USE_CREATE_USERS = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({ mutationFn: (values: userType) => USERS_MANAGEMENT_POST(values) })

export const USE_RESET_PASSWORD = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({ mutationFn: (values: resetPasswordType) => USERS_RESET_PASSWORD(values) })

export const USE_UPDATE_USERS = (): UseMutationResult<any, Error, any, unknown> =>
    useMutation({
        mutationFn: (values: userType) => USERS_MANAGEMENT_PATCH(values)
    })

//optimistic update single property data
export const optimisticUserMangaement = (
    queryClient: any,
    type: string,
    res: any,
    pagination?: PaginationTypes
): void => {
    const updateQueryData = (updater: (oldData: any) => any): void => {
        queryClient.setQueryData(
            ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', ''],
            (oldData: any) => updater(oldData)
        )
    }

    switch (type) {
        case 'user_edit':
            updateQueryData((old: UsersRoot) => ({
                ...old,
                data: {
                    ...old?.data,
                    data: old?.data?.data?.map((user: userType) => {
                        if (user.id === res?.data?.id) {
                            return res.data
                        }
                        return user
                    })
                }
            }))
            break
        case 'user_switch_status':
            updateQueryData((old: UsersRoot) => ({
                ...old,
                data: {
                    ...old?.data,
                    data: old?.data?.data?.map((user: userType) => {
                        if (user.id === res?.id) {
                            return { ...res, disabled: !res.disabled }
                        }
                        return user
                    })
                }
            }))
            break
        case 'user_add':
            queryClient.refetchQueries({
                queryKey: ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', '']
            })
            break
        default:
            queryClient.refetchQueries({
                queryKey: ['USE_GET_LIST_USERS', pagination?.current, pagination?.pageSize, '', '']
            })
            break
    }
}
