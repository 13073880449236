import { resetPasswordType, userType } from 'types/user-management.types'
import { apiRequest } from 'utils/axios'
import { USER_MANAGEMENT_APIS } from 'utils/eps'

export const USERS_MANAGEMENT_GET = (
    page: number | undefined,
    limit: number | undefined,
    search: string,
    status: string
): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.LIST_USERS(page, limit, search, status), method: 'get' })
}

export const USER_MANAGEMENT_ROLES_GET = (): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.USER_ROLES, method: 'get' })
}

export const USERS_MANAGEMENT_POST = (values: userType): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.CREATE_USER, method: 'post', data: values })
}

export const USERS_RESET_PASSWORD = (values: resetPasswordType): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.RESET_PASSOWRD, method: 'post', data: values })
}
export const USERS_MANAGEMENT_PATCH = (values: userType): Promise<unknown> => {
    const { id, ...dataToSend } = values
    return apiRequest.withToken({
        url: USER_MANAGEMENT_APIS.UPDATE_USER(id ?? ''),
        method: 'patch',
        data: dataToSend
    })
}

export const USER_PROFILE_GET = (cognitoId: string): Promise<unknown> => {
    return apiRequest.withToken({ url: USER_MANAGEMENT_APIS.USER_PROFILE(cognitoId), method: 'get' })
}
