import { getCurrentUser } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'

interface UserType {
    loginId?: string | undefined
    authFlowType?: any
    userId?: string | boolean
    username?: string
}
export default function useCurrentAuthenticatedUser(): {
    user: UserType
    loading: boolean
} {
    const [loading, setLoading] = useState<boolean>(true)
    const [user, setUser] = useState<UserType>({})

    useEffect(() => {
        const fetchCurrentUser = async (): Promise<void> => {
            try {
                const currentUser = await getCurrentUser()
                setUser(currentUser || {})
            } catch (error) {
                console.error(error)
                setUser({})
            } finally {
                setLoading(false)
            }
        }

        fetchCurrentUser()
    }, [])

    return { user, loading }
}
