/* eslint-disable no-unused-vars */
import { USE_USER_PROFILE } from 'components/user-management/api-helper/services'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { userSingleData } from 'types/user-management.types'
import useCurrentAuthenticatedUser from 'hooks/use-current-authenticated-user'
import { MainContext } from 'App'
import { allowedRoutes } from 'utils/components/routes'
import useWindowDimensions from 'hooks/use-window-dimensions'
import { SortingKeys, filterSendingKeys } from 'types/property-management.types'
import { PaginationTypes } from 'types/common.types'

type Props = { children: ReactNode }
export const CommonStateContext = createContext<{
    propertyPagination: PaginationTypes
    setPropertyPagination: (pagination: PaginationTypes) => void
    logOutOpen: boolean
    setLogOutOpen: (logOutOpen: boolean) => void
    collapsed: boolean
    setCollapsed: (collapsed: boolean) => void
    siderActive: string
    setSiderActive: (siderActive: string) => void
    previewOpen: boolean
    setPreviewOpen: (previewOpen: boolean) => void
    previewImage: string
    setPreviewImage: (previewImage: string) => void
    previewTitle: string
    setPreviewTitle: (previewTitle: string) => void
    userProfile: userSingleData | undefined
    profileLoading: boolean
    filterOptions: filterSendingKeys
    setFilterOptions: (filterOptions: filterSendingKeys) => void
    activeFilter: boolean
    setActiveFilter: (logOutOpen: boolean) => void
    sortingOptions: SortingKeys
    setSortingOptions: (sortingOptions: SortingKeys) => void
}>({
    propertyPagination: { current: 1, pageSize: 10, total: 0 },
    setPropertyPagination: (pagination: PaginationTypes) => pagination,
    logOutOpen: false,
    setLogOutOpen: (logOutOpen: boolean) => logOutOpen,
    collapsed: false,
    setCollapsed: (collapsed: boolean) => collapsed,
    siderActive: '',
    setSiderActive: (siderActive: string) => siderActive,
    previewOpen: false,
    setPreviewOpen: (previewOpen: boolean) => previewOpen,
    previewImage: '',
    setPreviewImage: (previewImage: string) => previewImage,
    previewTitle: '',
    setPreviewTitle: (previewTitle: string) => previewTitle,
    userProfile: undefined,
    profileLoading: false,
    filterOptions: {
        property_type: undefined,
        city: undefined,
        status: undefined,
        active: undefined,
        bedrooms: undefined,
        bathrooms: undefined,
        price: [],
        priceFrom: undefined,
        priceTo: undefined
    },
    setFilterOptions: (filterOptions: filterSendingKeys) => filterOptions,
    activeFilter: false,
    setActiveFilter: (logOutOpen: boolean) => logOutOpen,
    sortingOptions: {
        sort: undefined
    },
    setSortingOptions: (sortingOptions: SortingKeys) => sortingOptions
})

export default function CommonStates({ children }: Props): JSX.Element {
    const { width } = useWindowDimensions()
    const [logOutOpen, setLogOutOpen] = useState<boolean>(false)
    const [collapsed, setCollapsed] = useState<boolean>(width < 1440 ? true : false)
    const [siderActive, setSiderActive] = useState<string>('')
    const [previewOpen, setPreviewOpen] = useState<boolean>(false)
    const [previewImage, setPreviewImage] = useState<string>('')
    const [previewTitle, setPreviewTitle] = useState<string>('')
    const { user } = useCurrentAuthenticatedUser()
    const { data: userProfile, isFetching: profileLoading } = USE_USER_PROFILE(user?.username ? user?.username : '')
    const { setRolesArray, setRoutes, setProfile } = useContext(MainContext)
    const [filterOptions, setFilterOptions] = useState<filterSendingKeys>({
        property_type: undefined,
        city: undefined,
        status: undefined,
        active: ['active'],
        bedrooms: undefined,
        bathrooms: undefined,
        priceFrom: undefined,
        priceTo: undefined
    })
    const [sortingOptions, setSortingOptions] = useState<SortingKeys>({
        sort: null
    })
    const [propertyPagination, setPropertyPagination] = useState<PaginationTypes>({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [activeFilter, setActiveFilter] = useState<boolean>(false)

    useEffect(() => {
        if (userProfile) {
            if (userProfile?.data?.roles && userProfile?.data?.roles?.length > 0) {
                const roles = userProfile?.data?.roles?.map((role) => role.role)
                const routes = allowedRoutes(roles)
                setRolesArray(roles)
                setRoutes(routes)
                setProfile(userProfile?.data)
            } else if (userProfile?.data === null) {
                setProfile(userProfile?.data)
            } else if (userProfile?.data?.id) {
                setProfile(userProfile.data)
            }
        }
    }, [userProfile, setRolesArray, setRoutes, setProfile])
    return (
        <CommonStateContext.Provider
            value={{
                logOutOpen,
                setLogOutOpen,
                collapsed,
                setCollapsed,
                siderActive,
                setSiderActive,
                previewOpen,
                setPreviewOpen,
                previewImage,
                setPreviewImage,
                previewTitle,
                setPreviewTitle,
                userProfile,
                profileLoading,
                filterOptions,
                setFilterOptions,
                propertyPagination,
                setPropertyPagination,
                activeFilter,
                setActiveFilter,
                setSortingOptions,
                sortingOptions
            }}>
            {children}
        </CommonStateContext.Provider>
    )
}
