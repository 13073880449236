import { GetProp, UploadFile, UploadProps } from 'antd'
import { PropertyTagImgType, filterOptionType } from 'types/property-management.types'
import bachelorsImage from 'assests/images/property_images/bachelors.png'
import coLivingImage from 'assests/images/property_images/co-living.png'
import depositImage from 'assests/images/property_images/deposit.png'
import familyImage from 'assests/images/property_images/family.png'
import furnishedImage from 'assests/images/property_images/furnished.png'
import mealsImage from 'assests/images/property_images/meals.png'
import menImage from 'assests/images/property_images/men.png'
import s_furnishedImage from 'assests/images/property_images/semi-furnished.png'
import womenImage from 'assests/images/property_images/women.png'
import { cityRoot } from 'types/common.types'
import { ReactNode } from 'react'

export const initialCenter = {
    lat: 12.9716,
    lng: 77.5946
}

export const imageFormatRegex = /\.(jpg|jpeg|png|gif|webp|avif|jfif|pjpeg|pjp|bmp|ico|cur|tif|tiff)$/i

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]
export const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (): void => resolve(reader.result as string)
        reader.onerror = (error): void => reject(error)
    })
export const handlePreview = async (
    file: UploadFile,
    setPreviewImage: (previewImage: string) => void,
    setPreviewOpen: (previewOpen: boolean) => void,
    setPreviewTitle: (previewTitle: string) => void
): Promise<void> => {
    if (!file.fileName && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType)
    }
    setPreviewImage(file.fileName || (file.preview as string))
    setPreviewOpen(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
}

export function imageViewer(key: string, size: string): string {
    const previewComImgUrl = process.env.REACT_APP_COMPRESSED_IMAGE_URL
    const previewImgUrl = process.env.REACT_APP_IMAGE_URL
    if (size == 'raw') {
        return previewImgUrl + key
    } else {
        const keyPath = key
        const parts = keyPath.split('/')
        const lastIndex = parts.length - 1
        const newKey = parts.slice(0, lastIndex).join('/') + `/${size}_` + parts[lastIndex]
        return previewComImgUrl + newKey
    }
}

export const propertyFilterOptions = (addressData: cityRoot | undefined): filterOptionType[] => {
    return [
        {
            label: 'Property type',
            value: 'property_type',

            children: ['pg', 'rooms', 'house']?.map((item) => ({
                label: item.charAt(0).toUpperCase() + item.slice(1),
                value: item
            }))
        },
        {
            label: 'Bedrooms',
            value: 'bedrooms',

            children: ['1', '2', '3', '4', '5', '5+']?.map((item) => ({ label: item, value: item }))
        },
        {
            label: 'Bathrooms',
            value: 'bathrooms',

            children: ['1', '2', '3', '4', '5', '5+']?.map((item) => ({ label: item, value: item }))
        },
        {
            label: 'City',
            value: 'city',
            children: addressData?.data?.map((item) => ({
                label: item?.name,
                value: item?.name
            }))
        },
        {
            label: 'Status',
            value: 'status',

            children: ['available', 'vacating soon', 'occupied']?.map((item) => ({
                label: item.charAt(0).toUpperCase() + item.slice(1),
                value: item
            }))
        },
        {
            label: 'Live',
            value: 'active',

            children: ['active', 'inactive']?.map((item) => ({
                label: item.charAt(0).toUpperCase() + item.slice(1),
                value: item
            }))
        }
    ]
}

export const amountConverter = (amount: string | number): string => {
    const value =
        typeof amount === 'string'
            ? parseFloat(amount).toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
              })
            : amount.toLocaleString('en-IN', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0
              })
    return value
}

export const propertyTagsWithImage = (label: string): PropertyTagImgType | null => {
    const tags = [
        {
            label: 'Only for women',
            keyword: 'Only for women',
            image: womenImage,
            color: '#ED1CBE'
        },
        {
            label: 'Only for men',
            keyword: 'Only for men',
            image: menImage,
            color: '#1C58ED'
        },
        {
            label: 'Co-live',
            keyword: 'Co-live',
            image: coLivingImage,
            color: '#7E30A5'
        },
        {
            label: 'For bachelors',
            keyword: 'For bachelors',
            image: bachelorsImage,
            color: '#30A53C'
        },
        {
            label: 'Security deposit refund',
            keyword: 'Security deposit refund',
            image: depositImage,
            color: '#FD7B00'
        },
        {
            label: 'For families',
            keyword: 'For families',
            image: familyImage,
            color: '#E52424'
        },
        {
            label: 'Fully furnished',
            keyword: 'Fully furnished',
            image: furnishedImage,
            color: '#A61485'
        },
        {
            label: 'Meals included',
            keyword: 'Meals included',
            image: mealsImage,
            color: '#0DC4D0'
        },
        {
            label: 'Semi furnished',
            keyword: 'Semi furnished',
            image: s_furnishedImage,
            color: '#4C46C8'
        }
    ]
    const foundTag = tags.find((item) => item.label === label)
    return foundTag ? foundTag : null
}

// Extracting RGB values from hex color code
export const hexToRgb = (hex: string): string => {
    const bigint = parseInt(hex.replace('#', ''), 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return `${r}, ${g}, ${b}`
}

export const FAQCategories = [
    { value: 'renting', label: 'Renting' },
    { value: 'during_my_stay', label: 'During my stay' },
    { value: 'terms_of_stay', label: 'Terms of stay' },
    { value: 'payments', label: 'Payments' }
]

export const commonFilterOption = (input: string, option?: { label: ReactNode; value: string; title: string }): any => {
    return (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
}
